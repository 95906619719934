.container-background {
  background-color: #F7F8F9;
}

.full-container {

  width: 90%;
  margin: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

}

.search-delete {
  background-color: #F7F8F9;
  margin: 10px;
}

.border {
  background-color: white;
}

.label-row {
  color: #344054;
}
.table-boder {
  border: none;
}


