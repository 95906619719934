.custom-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px;
}

.custom-table th,
.custom-table td {
  padding: 20px;
  text-align: left;
}

.custom-table th {
  background-color: #f8f9fa;
  font-size: 85%;
}

.custom-table tbody tr {
  background-color: #ffffff;

  margin-bottom: 10px;
}
.custom-table tbody tr:hover {
  background-color: #f1f1f1;
}